<template>
  <div class="postbackReport">
    <!-- <div class="main-Title bgff"><h2>Postback Report New</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form v-model="postbackReport" label-width="90px" size="mini">
          <el-row
            :gutter="10"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row"
          >
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="TranscationId(s)" label-width="110px" class="mb5">
                <el-input
                  v-model="postbackReport.transactionIds"
                  placeholder="transactionIds eg:123 or 123,456"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="OfferId(s)" class="mb5">
                <el-input
                  v-model="postbackReport.offerIds"
                  placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col
              :xs="24"
              :sm="24"
              :md="8"
              :lg="8"
              :xl="6"
              :style="{ height: fullWidth < 1920 ? '35px' : '34px' }"
            >
              <el-form-item label="Date" class="mb5" label-width="50px">
                <el-date-picker
                  v-model="startToEndDate"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  class="w100"
                  align="right"
                  unlink-panels
                  range-separator="-"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="eventName" class="mb5" label-width="90px">
                <el-input v-model="postbackReport.eventName" placeholder="eventName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Affiliate(s)" class="mb5">
                <group-select
                  v-model="postbackReport.affiliateIds"
                  :data-source="affiliatesGroupList"
                  multiple
                  reserve-keyword
                  :loading="affiliateLoading"
                  clearable
                  filterable
                  collapse-tags
                  placeholder="Please select"
                  class="w100"
                  size="mini"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Source(s)" class="mb5" label-width="80px">
                <group-select
                  v-model="postbackReport.sourceIds"
                  :data-source="sourcesList"
                  multiple
                  :loading="sourceLoading"
                  clearable
                  filterable
                  collapse-tags
                  placeholder="Please select"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="TimeZone" class="mb5" label-width="80px">
                <el-select v-model="postbackReport.timezone" class="w100">
                  <el-option
                    v-for="item in timezoneOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="affClickId" class="mb5" label-width="90px">
                <el-input v-model="postbackReport.affClickId" placeholder="affClickId"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="rejectReason" class="mb5" label-width="110px">
                <el-input
                  v-model="postbackReport.rejectReason"
                  placeholder="rejectReason"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="rejectSubReason" class="mb5" label-width="110px">
                <el-input
                  v-model="postbackReport.rejectSubReason"
                  placeholder="rejectSubReason"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="24" align="right">
              <el-form-item class="mb0" label-width="0px">
                <el-button
                  type="primary"
                  :loading="listLoading"
                  @click="searchListClick(1)"
                  size="mini"
                  >In Browser
                </el-button>
                <el-button type="primary" @click="handleExport()" size="mini"
                  >Download Excel
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="listLoading"
          style="width:100%;"
          stripe
          size="mini"
          border
          :data="postbackReportList"
          highlight-current-row
        >
          <el-table-column
            label="clickTime"
            :fixed="fullWidth <= 768 ? false : 'left'"
            min-width="100"
            :resizable="false"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ dateChange(scope.row.clickTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="TransactionId"
            prop="transactionId"
            min-width="120"
            align="center"
            :fixed="fullWidth <= 768 ? false : 'left'"
            show-overflow-tooltip
            :resizable="false"
          ></el-table-column>
          <el-table-column label="Payout" prop="payoutValue"></el-table-column>
          <el-table-column
            label="Aff Payout"
            prop="affPayoutValue"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            label="TrackCountry"
            prop="country"
            min-width="120"
            align="center"
          ></el-table-column>
          <el-table-column label="Adjusted" min-width="100" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.adjusted == 0">下发</span>
              <span v-else-if="scope.row.adjusted == 1">扣除</span>
              <span v-else></span>
            </template>
          </el-table-column>
          <el-table-column
            label="adjustedType"
            prop="adjustedType"
            min-width="100"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="reject_reason"
            prop="rejectReason"
            min-width="100"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="reject_sub_reason"
            prop="rejectSubReason"
            min-width="100"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="SourceId"
            prop="sourceId"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            label="affClickId"
            prop="affClickId"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            label="AffiliateId"
            prop="affiliateId"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            label="EventName"
            prop="eventName"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            label="AffiliateSub"
            prop="affiliateSub"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            label="OriginalUrl"
            prop="originalUrl"
            min-width="100"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="RedirectUrl"
            prop="redirectUrl"
            min-width="100"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="PrivacyGuard" prop="privacyGuard" min-width="100" align="center">
            <template slot-scope="scope">
              {{ scope.row.privacyGuard == 1 ? '高级' : '普通' }}
            </template>
          </el-table-column>
          <el-table-column
            label="Create Time"
            prop="createTime"
            min-width="170"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="gaid"
            prop="gaid"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="idfa" prop="idfa" align="center"></el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageSize"
          :currentPage="currentPage"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>
<script>
  import PostbackReportNewCon from '../controllers/report/PostbackReportNew';

  export default {
    name: 'PostbackReportNew',
    ...PostbackReportNewCon,
  };
</script>
<style lang="scss" scoped></style>
<style lang="scss"></style>
